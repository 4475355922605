import React from "react";
import { ListenApple, ListenGoogle, ListenSpotify } from "../../../Buttons";

const ShareSocial = () => {
  return (
    <div>
      <div className="justify-center gap-4 xl:gap-14 items-center hidden lg:flex">
        <ListenApple />
        <ListenGoogle />
        <ListenSpotify />
      </div>
    </div>
  );
};

export default ShareSocial;
