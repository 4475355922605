import React from "react"
import { CardEpisode } from "../../../Cards"

import useEpisodes from "../../../../utils/useEpisodes"

const LatestEpisodes = () => {
  const data = useEpisodes()

  return (
    <div className="block mx-auto">
      <div className="">
        <p className="desktop-title text-center max-w-md mx-auto pt-8 pb-8">
          Latest Episodes
        </p>
        <div className="overflow-x-scroll snap-x flex items-start justify-start lg:justify-start lg:mx-auto gap-8 lg:gap-16 max-w-md mx-auto lg:max-w-3xl xl:max-w-6xl lg:px-8">
          {data.map(i => (
            <CardEpisode
              title={i.title}
              description={i.description}
              link={i.link}
              linkToDrive={i.linkToDrive}
              image={i.image}
              slug={i.slug}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LatestEpisodes
