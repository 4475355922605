import { useStaticQuery, graphql } from "gatsby"

const useEpisodes = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulEpisodes {
          nodes {
            title
            slug
            description {
              description
            }
            linkToEpisode
            linkToDrive
            image {
              id
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                height: 206
                width: 312
              )
            }
          }
        }
      }
    `
  )
  return data.allContentfulEpisodes.nodes.map(i => ({
    title: i.title,
    description: i.description.description,
    link: i.linkToEpisode,
    linkToDrive: i.linkToDrive,
    image: i.image.gatsbyImageData,
    slug: i.slug,
  }))
}

export default useEpisodes
